.modal_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  
  .modal_wrapper {
    height: 300px;
    width: 500px;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .modal_title h4 {
    text-align: center;
  }
  
  .modal_buttons {
    display: flex;
  }
  
  .modal_buttons button {
    height: 40px;
    padding: 0.5rem 2rem;
    margin: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    outline: none;
    background-color: #fff;
    border: 1px solid #eb1589;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .modal_buttons button:hover {
    background-color: #eb1589;
    color: #fff;
  }
  
  .modal_buttons button.delete {
    background-color: #eb1589;
    color: #fff;
  }
  
  .modal_buttons button.delete:hover {
    background-color: #fff;
    color: #000;
  }
  
  @media only screen and (max-width: 725px) {
    .modal_wrapper {
      height: 50%;
      width: 80%;
    }
  }
  
  @media only screen and (max-width: 525px) {
    .modal_title h2 {
      font-size: 1.3rem;
    }
  
    .modal_buttons button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .modal_wrapper {
      width: 90%;
    }
  }
  