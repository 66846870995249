.signIn_container {
  height: 100vh;
  width: 100%;
}

.signIn_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.signIn_left,
.signIn_right {
  height: 100%;
  flex: 1;
}

.signIn_left {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 11rem;
}

.signIn_leftLogo {
  width: 50%;
  margin: 2rem 0;
}

.signIn_leftLogo img {
  width: 75%;
}

.signIn_leftHeader {
  width: 100%;
}

.signIn_main_header {
  font-size: 3rem;
  font-weight: 900;
  line-height: 56px;
  letter-spacing: 0;
  margin-bottom: 1rem;
}

.signIn_main_subheader {
  opacity: 0.6;
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 3.5rem;
}

.signIn_leftForm {
  width: 70%;
}

.login_form_inputArea {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.login_form_inputArea label {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 1rem;
}

.login_form_inputArea input {
  font-size: 1rem;
  padding: 1rem 0.5rem;
  border: 1px solid #4d5f6833;
  border-radius: 0.75rem;
  outline: none;
  background-color: #fafafa;
}

.form_infoArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.login_form_inputArea .form_icon {
  position: absolute;
  bottom: 10%;
  right: 3%;
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.login_form_inputArea .form_icon.showPass {
  opacity: 0.6;
}

.form_infoArea .remember {
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.form_infoArea .remember input {
  margin-right: 0.2rem;
}

.form_infoArea .forgot a {
  text-decoration: none;
  color: #eb1589;
  font-weight: 500;
  cursor: pointer;
}

.signIn_leftForm button {
  width: 100%;
  border: none;
  outline: none;
  background-color: #4d5f68;
  color: #fff;
  font-size: 1rem;
  font-weight: 900;
  line-height: 24px;
  border-radius: 0.75rem;
  margin-top: 3.5rem;
  padding: 1rem 0;
  cursor: pointer;
}

.signIn_right .signIn_rightImg {
  height: 100%;
  width: 100%;
}

.signIn_rightImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Forgot password style */
.signIn_leftForm button.forgot_btn {
  margin-top: 2rem;
}

/* ----- Responsive Design ----- */
@media only screen and (max-width: 1030px) {
  .signIn_left {
    align-items: center;
    padding-left: 0;
  }

  .signIn_leftLogo {
    width: 80%;
  }

  .signIn_leftLogo img {
    width: 70%;
  }

  .signIn_leftHeader {
    width: 80%;
  }

  .signIn_leftForm {
    width: 80%;
  }
}

@media only screen and (max-width: 680px) {
  .signIn_wrapper {
    flex-direction: column-reverse;
  }

  .signIn_left,
  .signIn_right {
    width: 100%;
  }

  .signIn_leftLogo img {
    width: 50%;
  }

  .signIn_rightImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 480px) {
  .signIn_leftLogo {
    margin: 1rem 0;
  }

  .signIn_main_header {
    font-size: 2.2rem;
    line-height: 44px;
    margin: 0;
  }

  .signIn_main_subheader {
    font-size: 1rem;
    line-height: 20px;
    margin: 0.5rem 0 2rem 0;
  }

  .signIn_leftForm {
    width: 100%;
  }

  .login_form_inputArea {
    width: 80%;
    margin: 1rem 10%;
  }

  .form_infoArea {
    width: 80%;
    margin: 0 10%;
  }

  .login_form_inputArea label,
  .login_form_inputArea input {
    font-size: 0.9rem;
    line-height: 20px;
  }

  .signIn_leftForm button {
    width: 80%;
    margin: 2rem 0 2rem 10%;
  }

  .signIn_right {
    height: 20%;
  }
}
