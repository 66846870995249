.loader_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  color: #fff;
}

.loader_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader_main .loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader_main .loader_content {
  font-size: 2rem;
  margin-top: 1rem;
  color: #fff;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Responsive */
@media only screen and (max-width: 475px) {
  .loader_main .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    width: 80px;
    height: 80px;
  }

  .loader_main .loader_content {
    font-size: 1rem;
  }
}
