.legal_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/Images/bg.png");
  background-size: cover;
}

.legal_container .legal_wrapper {
  padding: 0 11.25rem;
}

.legalBody_title {
  width: 100%;
  color: #4d5f68;
  font-size: 1.9rem;
  line-height: 35px;
  font-weight: 500;
  text-align: center;
  padding: 2rem 0;
}

.legal_wrapper .legal_header_section {
  text-align: center;
}

.legal_header_section .legal_header {
  margin: 2rem 0;
}

.legal_header_section .legal_header .legal_title {
  margin-top: 4rem;
  color: var(--black);
  font-size: 3rem;
  font-weight: 500;
  line-height: 53px;
  text-align: center;
}

.legal_header_section .legal_header .legal_subtitle {
  color: #4d5f68;
}

.legal_header_section .legal_header_nav {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
}

.legal_header_nav .legal_nav_item {
  padding: 1rem 0;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}

.legal_header_nav .legal_nav_item a {
  text-decoration: none;
  color: #4d5f68;
  cursor: pointer;
  padding: 1rem 0;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 24px;
  text-align: center;
}

.legal_header_nav .legal_nav_item a:hover,
.legal_header_nav .legal_nav_item a.active {
  color: var(--main-pink);
  border-bottom: 2px solid var(--main-pink);
}

.legal_body .legalBody_area {
  margin: 2rem 0 9rem 0;
  color: #4d5f68;
  font-size: 0.85rem;
  line-height: 20px;
  font-weight: 500;
  text-align: justify;
}

.legal_body .legalBody_area span {
  font-size: 0.9rem;
  font-weight: 800;
}

/* ----------------------------------- Responsive Design ----- */
@media only screen and (max-width: 1180px) {
  .legal_container .legal_wrapper {
    padding: 0 6rem;
  }
}

@media only screen and (max-width: 1030px) {
  .legal_container .legal_wrapper {
    padding: 0 4rem;
  }
}

@media only screen and (max-width: 830px) {
  .legal_container .legal_wrapper {
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 680px) {
  .legal_container .legal_wrapper {
    padding: 0 2rem;
  }

  .legal_container .navbar_container .nav_right_menu_icon {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .legal_container .legal_wrapper {
    padding: 0 1rem;
  }

  .legal_header_nav .legal_nav_item a {
    letter-spacing: 0;
    line-height: 20px;
  }

  .legal_header_section .legal_header .legal_title {
    font-size: 2rem;
  }

  .legalBody_wrapper .legalBody_title {
    font-size: 1.5rem;
    line-height: 30px;
    padding: 1rem 0;
  }

  .legal_body .legalBody_area {
    margin: 2rem 0 4rem 0;
    line-height: 16px;
  }
}
