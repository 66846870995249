.unsubscribe_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/Images/bg.png");
  background-size: cover;
}

.unsubscribe_wrapper {
  padding: 0 11.25rem;
}

.unsubscribe_body {
  padding: 5rem 0 7rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unsubscribe_body .unsubscribe_title {
  color: var(--black);
  font-size: 3rem;
  font-weight: 500;
  line-height: 53px;
  text-align: center;
  width: 50%;
}

.unsubscribe_body .unsubscribe_main_img {
  margin: 2.4rem 0;
}

.unsubscribe_body .unsubscribe_subtitle {
  color: #828282;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
  width: 55%;
}

/* ----------------------------------- Responsive Design ----- */
@media only screen and (max-width: 1180px) {
  .unsubscribe_container .unsubscribe_wrapper {
    padding: 0 6rem;
  }
}

@media only screen and (max-width: 1030px) {
  .unsubscribe_container .unsubscribe_wrapper {
    padding: 0 4rem;
  }
}

@media only screen and (max-width: 830px) {
  .unsubscribe_container .unsubscribe_wrapper {
    padding: 0 3rem;
  }

  .unsubscribe_body {
    padding: 4rem 0 6rem 0;
  }

  .unsubscribe_body .unsubscribe_title {
    width: 80%;
  }

  .unsubscribe_body .unsubscribe_main_img {
    margin: 2rem 0;
  }

  .unsubscribe_body .unsubscribe_subtitle {
    font-size: 1.1rem;
    line-height: 25px;
    width: 80%;
  }
}

@media only screen and (max-width: 680px) {
  .unsubscribe_container .unsubscribe_wrapper {
    padding: 0 2rem;
  }

  .unsubscribe_container .navbar_container .nav_right_menu_icon {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .unsubscribe_container .unsubscribe_wrapper {
    padding: 0 1rem;
  }

  .unsubscribe_body {
    padding: 2rem 0 3rem 0;
  }

  .unsubscribe_body .unsubscribe_title {
    font-size: 2rem;
    line-height: 35px;
    width: 100%;
  }

  .unsubscribe_body .unsubscribe_subtitle {
    width: 100%;
  }
}
