@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700;900&display=swap");

/* ----- Global styles -----  */
:root {
  --main-pink: #eb1589;
  --color-grey: #4d5f68;
  --white: #fff;
  --black: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* ----- Common styles -----  */
.download_btns.gift {
  justify-content: flex-start;
}

.download_btns button {
  background-color: transparent;
  display: flex;
  column-gap: 0.75rem;
  border: transparent;
  cursor: pointer;
}

.download_icon {
  height: 6vh;
}

.download_icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.download_btn_text {
  text-align: left;
}

.download_btn_text > div {
  opacity: 0.6;
  color: #201212;
  font-size: 0.75rem;
  line-height: 16px;
  font-weight: 500;
}

.download_btn_text > span {
  color: var(--black);
  font-size: 1.1rem;
  line-height: 24px;
  font-weight: 500;
}

/* ----------------------------------- Navbar style -----  */
.navbar_container {
  position: relative;
  height: 110px;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.navbar_wrapper {
  height: 100%;
  padding: 0 11.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_container .navbar_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav_left_logo {
  width: 188px;
}

.nav_left_logo img {
  cursor: pointer;
  width: 100%;
}

.navbar_left .nav_left_menu {
  margin-left: 9rem;
  display: flex;
  column-gap: 4rem;
}

.navbar_left .nav_left_menu.legal {
  display: none;
}

.navbar_left .nav_left_menu span {
  font-size: 1.1rem;
  line-height: 21px;
  text-align: justify;
  font-weight: 500;
  color: var(--black);
  cursor: pointer;
}

.navbar_right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_right_code {
  margin-right: 1rem;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.nav_right_code img {
  height: 100%;
  width: 100%;
}

.nav_right_large_code {
  position: absolute;
  top: 6rem;
  border-radius: 1.25rem;
  background-color: #4d5f68;
  padding: 1.25rem;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  z-index: 999;
}

.nav_right_code:hover .nav_right_large_code {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.nav_right_large_code_text {
  color: var(--white);
  font-size: 1.25rem;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
}

.nav_right_btn button {
  border: none;
  outline: none;
  font-size: 1.1rem;
  line-height: 24px;
  font-weight: 500;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background-color: var(--main-pink);
  color: var(--white);
  cursor: pointer;
}

.navbar_mobile_btn {
  visibility: hidden;
  transform: scale(0);
}

/* ----------------------------------- Hero Section -----  */
.hero_container {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../src/assets/Images/bg.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero_wrapper {
  padding: 2.5rem 11.25rem 6rem 0;
  overflow: hidden;
}

.hero_body {
  display: flex;
}

.hero_body_left,
.hero_body_right {
  flex: 1;
}

.hero_body_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 11rem;
  row-gap: 3.5rem;
  background-image: url("../src/assets/Images/hero_shape.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 90%;
}

.hero_body_left_title {
  color: var(--white);
  font-size: 5.5rem;
  font-weight: 500;
  line-height: 90px;
  text-align: center;
  background: -webkit-linear-gradient(122.18deg, #eb1589 0%, #5928db 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero_body_left_subtitles {
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
}

.hero_body_left_subtitle {
  width: 90%;
  color: #676767;
  font-family: "Comic Sans MS";
  font-size: 1.2rem;
  line-height: 25px;
  text-align: center;
}

.download_btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.download_btns.gift {
  justify-content: center;
}

.hero_body_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hero_right_banner {
  width: 433px;
  height: 550px;
}

.hero_right_banner img {
  width: 100%;
  height: 100%;
}

.hero_body_bunny_wrapper {
  position: relative;
  margin: 10rem 0 5rem 0;
  width: 100%;
  padding: 0 11.25rem;
}

.hero_body_bunny_main {
  height: 100%;
  box-sizing: border-box;
  border-radius: 2rem 2rem 0 0;
  background-color: #ec7ebd;
  border: 5px solid #fbfbfb;
  box-shadow: 0 8px 10px 0 #c1b5bc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_body_bunny_img {
  position: absolute;
  bottom: 75%;
  height: 200px;
  width: 160px;
}

.hero_body_bunny_img img {
  height: 100%;
  width: 100%;
}

.hero_body_bunny_box {
  padding: 2rem 0 1.5rem 0;
}

.hero_body_bunny_box_title {
  color: var(--white);
  font-size: 2.5rem;
  line-height: 50px;
  font-weight: 500;
  text-align: center;
}

.hero_body_bunny_box_subtitle {
  color: var(--white);
  font-size: 1.5rem;
  line-height: 50px;
  text-align: center;
}

/* ----------------------------------- AboutUs Section -----  */
.aboutUs_container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ffdff0;
}

.aboutUs_container .line_img {
  position: absolute;
  left: 18%;
  top: 47%;
  transform: scale(0.5);
}

.aboutUs_wrapper {
  height: 100%;
  padding: 6rem 11.25rem;
  display: flex;
  column-gap: 8rem;
}

.aboutUs_banner {
  width: 40%;
  z-index: 99;
}

.aboutUs_banner_img {
  height: 600px;
  /* width: 280px; */
}

.aboutUs_banner_img img {
  height: 100%;
  /* width: 100%; */
}

.aboutUs_info_body {
  display: flex;
  flex-direction: column;
  row-gap: 3.5rem;
}

.aboutUs_info_body_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.aboutUs_info_header {
  margin-bottom: 4rem;
}

.aboutUs_title {
  color: var(--black);
  font-size: 2rem;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.aboutUs_subtitle {
  color: var(--color-grey);
  font-size: 1.1rem;
  line-height: 25px;
  font-weight: 500;
}

.aboutUs_info_body_icon {
  margin-right: 2.75rem;
}

.aboutUs_info_body_val {
  color: var(--color-grey);
  font-size: 1rem;
  line-height: 20px;
  font-weight: 500;
}

/* ----------------------------------- Features Section -----  */
.features_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features_container .features_wrapper {
  padding: 9.5rem 11.25rem 6rem 11.25rem;
}

.features_wrapper .features_top {
  text-align: center;
}

.features_top .features_top_header {
  color: var(--main-pink);
  font-size: 1.1rem;
  line-height: 20px;
  font-weight: 700;
}

.features_title {
  color: var(--black);
  font-size: 3rem;
  line-height: 56px;
  font-weight: 500;
  text-align: center;
  margin: 1.25rem 0;
}

.features_subtitle {
  color: var(--color-grey);
  font-size: 1.25rem;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
}

.features_bottom {
  margin-top: 3rem;
  width: 100%;
  /* padding: 3rem 0; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1.25rem;
}

.features_bottom_card {
  width: 23%;
  padding: 2.25rem 0.75rem 1.5rem 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 1.5rem;
  border: 2px solid var(--color-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom_card_img {
  height: 105px;
  width: 115px;
}

.bottom_card_img img {
  height: 100%;
  width: 100%;
}

.bottom_card_title {
  color: var(--color-grey);
  font-size: 1rem;
  line-height: 19px;
  font-weight: 500;
  text-align: center;
  margin: 0.5rem 0 1.5rem 0;
}

.bottom_card_val ol {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.bottom_card_val ol li {
  color: var(--color-grey);
  font-size: 0.75rem;
  line-height: 15px;
  font-weight: 500;
  /* text-align: justify; */
}
/* ----------------------------------- Screenshots Section -----  */
.screenshots_container {
  height: 100%;
  width: 100%;
  background: linear-gradient(309.11deg, #6515dd 0%, #eb1589 100%);
}

.screenshots_wrapper {
  width: 100%;
  height: 100%;
  padding: 2.5rem 0;
}

.screenshots_top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.screenshot_title {
  color: var(--white);
  font-size: 3rem;
  line-height: 56px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.25rem;
  padding: 0 11.25rem;
}

.screenshot_subtitle {
  color: var(--white);
  font-size: 1.25rem;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
  padding: 0 16rem;
}

.screenshots_middle {
  overflow: hidden;
}

.screenshots_images_wrapper {
  width: 200vw;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
}

.screenshot_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.screenshot_image_div img {
  height: 60vh;
  object-fit: cover;
}

.screenshots_bottom {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.screenshots_bottom .screenshots_arrow {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.screenshots_bottom .screenshots_arrow:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

/* ----------------------------------- Gift Section -----  */
.gift_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../src/assets/Images/oval_bg.png");
  background-position: center;
  background-size: cover;
}

.gift_wrapper {
  padding: 8rem 11.25rem;
  color: var(--white);
}

.gift_title {
  font-size: 9.25rem;
  line-height: 150px;
  font-weight: 500;
  letter-spacing: -4.09px;
}

.gift_title span {
  background: linear-gradient(91.29deg, #eb1589 -1.41%, #5015eb 99.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gift_subtitle {
  margin: 2rem 0;
  font-size: 3rem;
  line-height: 56px;
  letter-spacing: -1.31px;
}

/* ----------------------------------- Footer Section -----  */
.footer_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 3.5rem; */
  background-color: #fafafa;
}

.footer_container .footer_wrapper {
  width: 100%;
}

.footer_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 3rem 11.25rem;
}

.footer_top_one_img {
  width: 140px;
}

.footer_top_one_img img {
  width: 100%;
}

.footer_top_two,
.footer_top_three,
.footer_top_four {
  padding-top: 1.5rem;
}

.footer_top_one_info {
  color: var(--color-grey);
  font-size: 1rem;
  line-height: 20px;
  font-weight: 500;
}

.footer_top_two_title,
.footer_top_three_title {
  color: var(--color-grey);
  font-size: 1.25rem;
  line-height: 27px;
  font-weight: 900;
  padding-bottom: 1.5rem;
}

.footer_top_two_links {
  color: var(--color-grey);
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.footer_top_three_link {
  color: var(--color-grey);
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.footer_top_three_link span {
  color: var(--main-pink);
  transform: scale(0.9);
  margin: 0.25rem 0.75rem 0 0;
}

.footer_top_four {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
}

.footer_middle {
  background-color: var(--main-pink);
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 0.5rem 0;
}

.footer_bottom a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  color: var(--color-grey);
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
}

.footer_bottom span {
  padding: 0 0.2rem;
  transform: scale(0.9);
  color: var(--color-grey);
  margin-top: 0.25rem;
}

/* ----------------------------------- Responsive Design ----- */
@media only screen and (max-width: 1300px) {
  .navbar_left .nav_left_menu {
    margin-left: 4rem;
    column-gap: 3rem;
  }
}

@media only screen and (max-width: 1180px) {
  /* ---------- Navbar style -----  */
  .navbar_wrapper {
    padding: 0 6rem;
  }

  /* ---------- Hero Section -----  */
  .hero_wrapper {
    padding: 2.5rem 2rem 5rem 2rem;
  }

  .hero_body_left {
    padding-left: 4rem;
    row-gap: 2.5rem;
  }

  .hero_body_right {
    display: flex;
    justify-content: center;
  }

  .hero_right_banner {
    width: 370px;
    height: 500px;
  }

  /* ---------- AboutUs Section -----  */
  .aboutUs_wrapper {
    padding: 5rem 6rem;
    column-gap: 6rem;
  }

  .aboutUs_container .line_img {
    left: 10%;
    top: 40%;
  }

  /* ---------- Features Section -----  */
  .features_container .features_wrapper {
    padding: 6rem;
  }

  .features_bottom {
    margin-top: 3rem;
    padding: 1rem 0;
    gap: 2rem;
  }

  .features_bottom_card {
    width: 230px;
  }

  /* ---------- Screenshots Section -----  */
  .screenshot_title {
    padding: 0 6rem;
  }

  .screenshot_subtitle {
    padding: 0 5rem;
  }

  /* ---------- Gift Section -----  */
  .gift_wrapper {
    padding: 6rem;
  }

  /* ---------- Footer Section -----  */
  .footer_top {
    padding: 3rem 6rem;
  }
}

@media only screen and (max-width: 1030px) {
  /* ---------- Navbar style -----  */
  .navbar_wrapper {
    padding: 0 4rem;
  }

  .navbar_left .nav_left_menu {
    margin-left: 2rem;
    column-gap: 2rem;
  }

  /* ---------- Hero Section -----  */
  .hero_wrapper {
    padding: 2.5rem 1rem 6rem 1rem;
  }

  .hero_body_left {
    padding-left: 3rem;
    row-gap: 2rem;
  }

  .hero_right_banner {
    width: 340px;
    height: 450px;
  }

  .hero_body_left_title {
    font-size: 4rem;
    line-height: 70px;
  }

  .hero_body_left_subtitles {
    row-gap: 1rem;
  }

  .hero_body_left_subtitle {
    font-size: 1.1rem;
  }

  .hero_body_bunny_wrapper {
    margin: 7rem 0 3rem 0;
    padding: 0 4rem;
  }

  /* ---------- AboutUs Section -----  */
  .aboutUs_wrapper {
    padding: 5rem 4rem;
    column-gap: 4rem;
  }

  .aboutUs_banner_img {
    height: 500px;
    /* width: 220px; */
  }

  .aboutUs_container .line_img {
    left: 0%;
    top: 25%;
  }

  .aboutUs_info_body {
    row-gap: 2rem;
  }

  .aboutUs_info_header {
    margin-bottom: 2rem;
  }

  .aboutUs_title {
    margin-bottom: 1rem;
  }

  .aboutUs_info_body_item {
    align-items: flex-start;
  }

  .aboutUs_info_body_icon {
    margin-right: 1rem;
  }

  /* ---------- Features Section -----  */
  .features_container .features_wrapper {
    padding: 5rem 4rem;
  }

  .features_bottom {
    padding: 0;
  }

  /* ---------- Screenshots Section -----  */
  .screenshot_title,
  .screenshot_subtitle {
    padding: 0 4rem;
  }

  .screenshots_images_wrapper {
    height: 65vh;
  }

  /* ---------- Gift Section -----  */
  .gift_wrapper {
    padding: 5rem 4rem;
  }

  .gift_title {
    font-size: 7rem;
    line-height: 110px;
  }

  .gift_subtitle {
    font-size: 2rem;
    line-height: 40px;
  }

  /* ---------- Footer Section -----  */
  .footer_top {
    padding: 3rem 4rem;
  }
}

@media only screen and (max-width: 880px) {
  /* ---------- Navbar style -----  */
  .navbar_wrapper {
    padding: 0 3rem;
  }

  .navbar_left .nav_left_menu span {
    font-size: 1rem;
  }

  .nav_left_logo {
    width: 170px;
  }

  .nav_right_code {
    height: 40px;
    width: 40px;
  }

  /* ---------- Hero Section -----  */
  .hero_body_left {
    padding-left: 4rem;
  }

  .hero_body_left_title {
    font-size: 3rem;
    line-height: 60px;
  }

  .hero_right_banner {
    width: 300px;
    height: 420px;
  }

  .hero_body_bunny_wrapper {
    padding: 0 2rem;
  }

  /* ---------- AboutUs Section -----  */
  .aboutUs_wrapper {
    padding: 5rem 2rem;
    column-gap: 1.5rem;
  }

  .aboutUs_info_body {
    row-gap: 1.5rem;
  }

  .aboutUs_info_header {
    margin-bottom: 1.5rem;
  }

  .aboutUs_banner_img {
    height: 450px;
    min-width: 200px;
  }

  .aboutUs_container .line_img {
    left: -5%;
    transform: scale(0.35);
  }

  /* ---------- Features Section -----  */
  .features_container .features_wrapper {
    padding: 4rem 2rem;
  }

  /* ---------- Screenshots Section -----  */
  .screenshot_title,
  .screenshot_subtitle {
    padding: 0 2rem;
  }

  .screenshots_images_wrapper {
    height: 60vh;
  }

  /* ---------- Gift Section -----  */
  .gift_wrapper {
    padding: 5rem 2rem;
  }

  .gift_title {
    font-size: 5rem;
    line-height: 100px;
  }

  .gift_subtitle {
    font-size: 1.75rem;
    line-height: 30px;
  }

  /* ---------- Footer Section -----  */
  .footer_top {
    padding: 3rem 2rem;
  }

  .footer_top_one,
  .footer_top_two,
  .footer_top_three,
  .footer_top_four {
    padding: 0;
    width: 230px;
  }

  .footer_top_four {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
  }
}

@media only screen and (max-width: 680px) {
  /* ---------- Navbar style -----  */
  .navbar_container {
    height: 10vh;
  }

  .navbar_left .nav_left_menu {
    position: absolute;
    left: 0;
    top: 10vh;
    height: 0;
    width: 0;
    transition: all 0.5s ease;
    margin: 0;
  }

  .navbar_left .nav_left_menu.mobile {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 10vh;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    transition: all 0.3s ease;
    z-index: 10;
  }

  .navbar_left .nav_left_menu span {
    margin-top: 3rem;
    font-size: 1rem;
    display: none;
  }

  .navbar_left .nav_left_menu.mobile span {
    display: block;
    transition: all 0.3s ease;
  }

  .navbar_right .navbar_mobile_btn.active {
    position: absolute;
    top: 20%;
    right: 0;
    visibility: visible;
    transform: scale(1.5);
    transition: all 0.5s ease;
  }

  .nav_right_code {
    display: none;
    margin: 0;
    transform: scale(0.7);
  }

  .navbar_wrapper .navbar_right {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .navbar_right .nav_right_btn button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    margin-right: 1rem;
  }

  /* ---------- Hero Section -----  */
  .hero_wrapper {
    max-width: 100vw;
  }

  .hero_body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero_body_left {
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    background-size: 70%;
    background-position: center;
  }

  .hero_body_right {
    margin-top: 2rem;
  }

  .hero_body_bunny_main {
    padding: 0 1rem;
  }

  .hero_body_bunny_box_title {
    font-size: 2rem;
    line-height: 40px;
  }

  .hero_body_bunny_box_subtitle {
    font-size: 1.3rem;
    line-height: 30px;
  }

  /* ---------- AboutUs Section -----  */
  .aboutUs_wrapper {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    row-gap: 4rem;
    padding: 3rem 2rem;
  }

  .aboutUs_info_body_icon {
    margin-right: 1rem;
  }

  .aboutUs_wrapper .aboutUs_banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aboutUs_container .line_img {
    display: none;
  }

  /* ---------- Features Section -----  */
  .features_container .features_wrapper {
    padding: 4rem 1rem;
  }

  /* ---------- Screenshots Section -----  */
  .screenshots_images_wrapper {
    width: 333.3vw;
  }

  .screenshot_title {
    font-size: 2rem;
    line-height: 40px;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  .screenshot_subtitle {
    font-size: 1.1rem;
    line-height: 24px;
    padding: 0 1rem;
  }

  .screenshots_middle {
    margin-top: 2rem;
  }

  .screenshots_bottom {
    column-gap: 2rem;
  }

  .screenshots_bottom .screenshots_arrow {
    height: 35px;
    width: 35px;
    z-index: 999;
  }

  /* ---------- Gift Section -----  */
  .gift_wrapper {
    padding: 3rem 1rem 5rem 1rem;
  }

  .gift_title {
    font-size: 4rem;
  }

  .gift_subtitle {
    font-size: 1.5rem;
  }

  /* ---------- Footer Section -----  */
  .footer_top {
    padding: 3rem 1rem;
    justify-content: center;
  }

  .footer_top_one,
  .footer_top_two,
  .footer_top_three,
  .footer_top_four {
    width: 250px;
  }

  .footer_top_two_title,
  .footer_top_three_title {
    padding-bottom: 1rem;
  }

  .footer_top_two_links {
    margin-bottom: 0.5rem;
  }

  .footer_top_three_link span {
    margin: 0.3rem 0.25rem 0 0;
  }

  .footer_bottom a {
    padding: 1rem 0;
    font-weight: 500;
  }
}

@media only screen and (max-width: 480px) {
  /* ----- Common styles -----  */
  .download_btn_text > div {
    font-size: 0.7rem;
    line-height: 12px;
  }

  .download_btn_text > span {
    font-size: 0.9rem;
    line-height: 20px;
  }

  /* ---------- Navbar style -----  */
  .navbar_wrapper {
    padding: 0 1rem;
  }

  .navbar_right .nav_right_btn button {
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
  }

  /* ---------- Hero Section -----  */
  .hero_wrapper {
    padding: 2rem 0rem 5rem 0;
  }

  .hero_body_left {
    padding-bottom: 1rem;
    background-size: 90%;
  }

  .hero_body_left_title {
    font-size: 2.5rem;
    line-height: 45px;
  }

  .hero_body_left_subtitle {
    width: 100%;
    font-size: 1rem;
    line-height: 20px;
  }

  .hero_right_banner {
    width: 280px;
    height: 380px;
  }

  .hero_body_bunny_wrapper {
    padding: 0 1rem;
  }

  .hero_body_bunny_img {
    height: 160px;
    width: 120px;
  }

  .hero_body_bunny_box_title {
    font-size: 1.5rem;
    line-height: 30px;
  }

  .hero_body_bunny_box_subtitle {
    font-size: 1.1rem;
    line-height: 24px;
  }

  /* ---------- AboutUs Section -----  */
  .aboutUs_wrapper {
    row-gap: 1rem;
    padding: 2rem;
  }

  .aboutUs_title {
    text-align: center;
    font-size: 2.5rem;
  }

  .aboutUs_subtitle {
    font-size: 1rem;
    line-height: 22px;
  }

  .aboutUs_info_body_val {
    font-size: 0.9rem;
  }

  /* ---------- Features Section -----  */
  .features_container .features_wrapper {
    padding: 2rem 1rem;
  }

  .features_title {
    font-size: 2.5rem;
    line-height: 40px;
    margin: 1rem 0;
  }

  .features_subtitle {
    font-size: 1rem;
    line-height: 22px;
  }

  .features_bottom {
    margin-top: 2rem;
  }

  .features_bottom_card {
    width: 250px;
    padding: 1.5rem 0.5rem;
  }

  .bottom_card_title {
    margin-bottom: 1rem;
  }

  .bottom_card_val ol {
    row-gap: 0.5rem;
  }

  .bottom_card_val ol li {
    text-align: left;
  }

  /* ---------- Screenshots Section -----  */
  .screenshots_middle {
    margin: 0;
  }

  .screenshot_title {
    line-height: 35px;
  }

  .screenshot_subtitle {
    font-size: 1rem;
    line-height: 20px;
  }

  .screenshots_images_wrapper {
    height: 55vh;
  }

  /* ---------- Gift Section -----  */
  .gift_title {
    line-height: 80px;
    text-align: center;
  }

  .gift_subtitle {
    text-align: center;
  }

  /* ---------- Footer Section -----  */
  .footer_top {
    padding: 2rem 1rem;
    justify-content: flex-start;
    column-gap: 1rem;
  }

  .footer_top_one_img {
    width: 110px;
  }

  .footer_top_one {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer_top_one,
  .footer_top_three {
    width: 100%;
    text-align: center;
  }

  .footer_top_two,
  .footer_top_four {
    width: 45%;
  }

  .footer_top_three {
    order: 5;
  }

  .footer_top_two_links {
    font-weight: 500;
  }

  .footer_top_two_title,
  .footer_top_three_title {
    padding-bottom: 0.5rem;
  }

  .footer_top_three_link {
    justify-content: center;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  /* ---------- Navbar Style -----  */
  .nav_left_logo {
    width: 150px;
  }

  .nav_right_code {
    margin: 0;
  }

  .navbar_right .nav_right_btn button {
    line-height: 16px;
    padding: 0.5rem;
    margin: 0 1rem 0 0.25rem;
  }

  /* ---------- Hero Section -----  */
  .hero_wrapper {
    padding-bottom: 3rem;
  }

  .hero_body_bunny_img {
    height: 150px;
    width: 110px;
  }

  /* ---------- AboutUs Section -----  */
  .aboutUs_wrapper {
    padding: 2rem 0;
  }

  .aboutUs_info_header,
  .aboutUs_info_body {
    padding: 0 1rem;
  }

  /* ---------- Screenshots Section -----  */

  .screenshots_images_wrapper {
    height: 45vh;
  }
}
